/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useKeycloakManager } from '../../../utils/keycloak'
import { useLayout } from '../../../_metronic/layout/core'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { AsideMenu } from './AsideMenu'
import { useLanguage } from '../../../utils/l10n/LanguageProvider'

const AsideDefault: FC = () => {
  const { logout } = useKeycloakManager()
  const { config, classes } = useLayout()
  const { aside } = config
  const { t } = useLanguage()

  return (
    <div
      id="kt_aside"
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to={t('/', '/en')}>
            <img alt="Logo" className="h-25px logo" src={toAbsoluteUrl('/media/logos/logo-light.png')} />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to={t('/', '/en')}>
            <img alt="Logo" className="h-25px logo" src={toAbsoluteUrl('/media/logos/logo-light.png')} />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
          >
            <KTSVG path={'/media/icons/duotune/arrows/arr080.svg'} className={'svg-icon-1 rotate-180'} />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className="aside-footer flex-column-auto pt-5 pb-7 px-5" id="kt_aside_footer">
        <button
          className="btn btn-custom btn-white w-100"
          data-bs-toggle="tooltip"
          data-bs-trigger="hover"
          data-bs-dismiss-="click"
          title={t('로그아웃', 'Sign-out')}
          onClick={logout}
        >
          <i className="fas fa-sign-out-alt fs-6" />
          <span className="btn-label">{t('로그아웃', 'Sign-out')}</span>
        </button>
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { AsideDefault }
