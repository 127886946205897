/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styled from 'styled-components'
import { AsideMenuItem } from './AsideMenuItem'
import { useLanguage } from '../../../utils/l10n/LanguageProvider'

const Seperator = styled.hr`
  width: 100%;
  color: white;
`

const AsideSection: React.FC = ({ children }) => (
  <div className="menu-item">
    <div className="menu-content mt-4 pb-2">
      <Seperator className="m-0 mb-6" />
      <span className="menu-section text-muted text-uppercase fs-8 ls-1">{children}</span>
    </div>
  </div>
)

export function AsideMenuMain() {
  const { language, t } = useLanguage()
  return (
    <>
      <AsideMenuItem to={t('/', '/en')} title={t('API DOCS 홈', 'Home')} fontIcon="fas fa-home" exact />
      <AsideMenuItem to={t('/profile', '/en/profile')} title={t('내 프로필', 'My Profile')} fontIcon="fas fa-user" />

      <AsideSection>{t('OPEN API 들어가기', 'Introduction')}</AsideSection>
      <AsideMenuItem
        to={t('/auth', '/en/auth')}
        title={t('인증 및 인가', 'Authn & Authz')}
        fontIcon="fas fa-shield-alt"
      />
      <AsideMenuItem to={t('/errors', '/en/errors')} title={t('에러 처리', 'Handling Errors')} fontIcon="fas fa-bug" />
      {language !== 'en' && (
        <>
          <AsideMenuItem to={t('/testing', '/en/testing')} title={t('테스팅', 'Testing')} fontIcon="fas fa-atom" />
          <AsideMenuItem
            to={t('/releases', '/en/releases')}
            title={t('릴리즈 노트', 'Release Notes')}
            fontIcon="fas fa-list-ul"
          />
        </>
      )}

      <AsideSection>{t('제품별 API 레퍼런스', 'API References')}</AsideSection>
      <AsideMenuItem
        to={t('/client', '/en/client')}
        title={t('고객사 정보 API', 'Client API')}
        fontIcon="fas fa-id-card-alt"
      />
      <AsideMenuItem to={t('/order', '/en/order')} title={t('주문 관리 API', 'Order API')} fontIcon="fas fa-plus" />
      <AsideMenuItem to={t('/trace', '/en/trace')} title={t('화물 추적 API', 'Trace API')} fontIcon="fas fa-map" />
      <AsideMenuItem
        to={t('/invoice', '/en/invoice')}
        title={t('송장 이미지 API', 'Invoice API')}
        fontIcon="fas fa-file-invoice"
        exact={true}
      />
      <AsideMenuItem
        to={t('/invoiceregion', '/en/invoiceregion')}
        title={t('권역 정보 API', 'Region API')}
        fontIcon="fas fa-globe-asia"
        exact={true}
      />

      <AsideSection>{t('언어별 레퍼런스', 'Languages')}</AsideSection>
      <AsideMenuItem
        to={t('/en', '/')}
        title={t('English (영어)', '한국어 (Korean)')}
        disableActive
        fontIcon="fas fa-language"
      />
    </>
  )
}
