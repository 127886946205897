import React from 'react'
import { PageTitle } from '../layouts'
import { WarningPage } from '../components'
import { useLanguage } from '../utils/l10n/LanguageProvider'

const PermissionDenied: React.FC = () => {
  const { t } = useLanguage()
  return (
    <>
      <PageTitle description="Permission Denied">{t('접근 권한이 없습니다', "You don't have a permission")})</PageTitle>
      <WarningPage title={t('접근 권한이 없습니다!', "You don't have a permission")} />
    </>
  )
}

export default PermissionDenied
