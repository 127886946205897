import { FC, createContext, useContext, useState, useCallback } from 'react'

export type Translator = (ko: string, en?: string) => string

type LanguageProps = {
  language: string
  setLanguage: (language: string) => void
  t: Translator
}

const LanguageContext = createContext<LanguageProps>({
  language: 'ko',
  setLanguage: (language: string) => {},
  t: (ko: string, en?: string) => ko,
})

export const LanguageProvider: FC = ({ children }) => {
  const [language, setLanguage] = useState('ko')
  const t = useCallback(
    (ko: string, en?: string) => {
      if (language === 'en') return en ?? ko
      return ko
    },
    [language],
  )
  return <LanguageContext.Provider value={{ language, setLanguage, t }}>{children}</LanguageContext.Provider>
}

export function useLanguage() {
  return useContext(LanguageContext)
}
