import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './styles/main.scss'
import RootRoutes from './routes/RootRoutes'
import { LayoutConfig, LayoutProvider, LayoutSetup } from './layouts'
import { AuthenticationProvider, KeycloakManager, PermissionProvider } from './utils/keycloak'
import { ToastContainer } from 'react-toastify'
import { ScreenProvider } from './utils/screen'
import { LanguageProvider } from './utils/l10n/LanguageProvider'

// Bootstrap Sass 변수들을 styled-components에서 사용할 수 있도록 테마 컨텍스트에 전달
// eslint-disable-next-line import/no-webpack-loader-syntax
const theme = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!./styles/extraction.scss')

LayoutSetup.updatePartialConfig(LayoutConfig)

const App: React.FC<{ keycloakManager: KeycloakManager }> = ({ keycloakManager }) => {
  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AuthenticationProvider keycloakManager={keycloakManager}>
            <PermissionProvider>
              <LayoutProvider>
                <ScreenProvider>
                  <ToastContainer hideProgressBar />
                  <RootRoutes />
                </ScreenProvider>
              </LayoutProvider>
            </PermissionProvider>
          </AuthenticationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </LanguageProvider>
  )
}

export default App
