import React, { FC, lazy, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from './Route'
import { Layout } from '../layouts'

import { FallbackView } from '../_metronic/partials'

const RootRoutes: FC = () => {
  const KoRoutes = lazy(() => import('./KoRoutes'))
  const EnRoutes = lazy(() => import('./EnRoutes'))

  return (
    <Switch>
      <Layout>
        <Suspense fallback={<FallbackView />}>
          <Switch>
            <Route path="/en" component={EnRoutes} />
            <Route path="/" component={KoRoutes} />
          </Switch>
        </Suspense>
      </Layout>
    </Switch>
  )
}

export default RootRoutes
